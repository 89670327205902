import React from 'react';
import './ActiveReservations.css';
import { ReservationDto } from '../../api/BookMeADeskApi';
import moment from 'moment';

type Props = {
  reservations: ReservationDto[];
  onCancelReservation: (reservationId: string) => void;
};

const ActiveReservations = ({ reservations, onCancelReservation }: Props) => {
  return (
    <div className="ActiveReservations FadeIn">
      {reservations.length === 0 ? (
        <div className="ActiveReservations__container FadeIn">
          <h4 className="ActiveReservations__header">Your have no active reservations.</h4>
          <div className="ActiveReservations__message">Reserve a desk in order to work from our office.</div>
          <div className="ActiveReservations__empty FadeIn">
            <span className="ActiveReservations__iconDesk" />
          </div>
        </div>
      ) : (
        <div className="ActiveReservations__container FadeIn">
          <div className="ActiveReservations__officeImage" />
          <div className="ActiveReservations__reservations">
            <h3 className="ActiveReservations__reservationsTitle">Your reservations</h3>
            <ul className="ActiveReservations__reservationsList">
              {reservations.map((x) => (
                <li key={x.id}>
                  <span className="ActiveReservations__iconDelete" onClick={() => onCancelReservation(x.id!)} />
                  <span className="ActiveReservations__iconCalendar" /> {moment(x.date).format('ddd, D MMM')} - {x.type}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default ActiveReservations;
