import React from 'react';
import './CTA.css';
import { Button } from 'antd';
import { Link } from 'react-router-dom';

const CTA = () => {
  return (
    <div className="CTA">
      <hr className="CTA__line" />
      <div className="CTA__buttonContainer">
        <Button className="CTA__Button Button Button--secondary" block={true}>
          <Link to="/book-presence">{"I'm here!"}</Link>
        </Button>
        <Button className="CTA__Button Button Button--primary" block={true}>
          <Link to="/book-desk">Book me a desk</Link>
        </Button>
      </div>
    </div>
  );
};

export default CTA;
