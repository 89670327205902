import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import AdalConfig from './config/AdalConfig';
import { AuthContext } from './services/Auth';
import AnonymousApp from './AnonymousApp';

export const anonymousPathPrefix = '/anonymous';

// Handle possible callbacks on id_token or access_token
AuthContext.handleWindowCallback();

// Extra callback logic, only in the actual application, not in iframes in the app
if (window === window.parent && window === window.top && !AuthContext.isCallback(window.location.hash)) {
  // Having both of these checks is to prevent having a token in localstorage, but no user.
  if (!AuthContext.getCachedToken(AdalConfig.clientId) || !AuthContext.getCachedUser()) {
    if (window.location.pathname.includes(anonymousPathPrefix)) {
      ReactDOM.render(<AnonymousApp />, document.getElementById('root'));
    } else {
      AuthContext.login();
    }
  } else {
    AuthContext.acquireToken(AdalConfig.endpoints.api, (message, token) => {
      if (token) {
        ReactDOM.render(<App />, document.getElementById('root'));
      }
    });
  }
}
