import React, { ReactNode } from 'react';
import './Content.css';
import classNames from 'classnames';

type Props = {
  children: ReactNode;
  className?: string;
  navigationBackground?: boolean;
};

const Content = ({ children, className, navigationBackground }: Props) => {
  return (
    <div
      className={classNames('Content', className, {
        'Content--navigationBackground': navigationBackground,
      })}
    >
      {children}
    </div>
  );
};

export default Content;
