import React from 'react';
import './BookDeskPage.css';
import Header from '../components/Header/Header';
import BookDesk from '../components/BookDesk/BookDesk';
import { Helmet } from 'react-helmet-async';
import Content from '../components/Content/Content';
import PageHeader from '../components/PageHeader/PageHeader';

const BookDeskPage = () => {
  return (
    <>
      <Helmet>
        <title>Book a desk - Book me a desk!</title>
        <meta name="theme-color" content="#fffefd" />
      </Helmet>
      <Header title="Book a desk" />
      <Content>
        <div className="BookDeskPage Page FadeIn">
          <PageHeader icon="desk" message="Choose one or more dates this week or the next." />
          <BookDesk reservationType="Desk" />
        </div>
      </Content>
    </>
  );
};

export default BookDeskPage;
