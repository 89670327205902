import React, { useEffect, useState } from 'react';
import Logo from '../components/Logo/Logo';
import ActiveReservations from '../components/ActiveReservations/ActiveReservations';
import { CancelReservationRequest, ICancelReservationRequest, ReservationDto, ReservationsClient } from '../api/BookMeADeskApi';
import ApiConfig from '../config/ApiConfig';
import Api from '../services/Api';
import { Button } from 'antd';
import './HomePage.css';
import HelloMessage from '../components/HelloMessage/HelloMessage';
import { Link } from 'react-router-dom';
import Loader from '../components/Loader/Loader';
import { Helmet } from 'react-helmet-async';
import CTA from '../components/CTA/CTA';
import { AuthContext } from '../services/Auth';
import Content from '../components/Content/Content';

const HomePage = () => {
  const reservationsClient = new ReservationsClient(ApiConfig.baseURL, Api);

  const [reservations, setReservations] = useState<ReservationDto[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      const reservations = await reservationsClient.get();
      setReservations(reservations);
    };
    fetchData()
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onCancelReservation = async (reservationId: string) => {
    try {
      const request: ICancelReservationRequest = {
        reservationId: reservationId,
      };
      await reservationsClient.cancel(request as CancelReservationRequest);
      const newReservations = reservations!.filter((x) => x.id !== reservationId);
      setReservations(newReservations);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Helmet>
        <title>Reservations - Book me a desk!</title>
        <meta name="theme-color" content="#f8f4f1" />
      </Helmet>
      <Content navigationBackground={true}>
        <div className="HomePage Page Page--background Container Container--vertical FadeIn">
          <Logo />
          <HelloMessage text={`Hello ${AuthContext.getCachedUser()?.profile?.given_name}`} />
          {isLoading ? (
            <Loader full />
          ) : (
            <div className="HomePage__restrictionsContainer FadeInDown">
              {reservations && <ActiveReservations reservations={reservations} onCancelReservation={onCancelReservation} />}
              <Link to="/restrictions">
                <Button className="HomePage__restrictionsButton Button" block={true}>
                  <span className="HomePage__restrictionsText">Current restrictions</span>
                  <span className="HomePage__restrictionsArrow" />
                </Button>
              </Link>
              <div className="Page__bottom">
                <CTA />
              </div>
            </div>
          )}
        </div>
      </Content>
    </>
  );
};

export default HomePage;
