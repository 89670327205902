import React from 'react';
import './Header.css';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';

type Props = {
  title: string;
  hideBackButton?: boolean;
  hide?: () => void;
  classes?: string;
};

const Header = ({ title, hideBackButton, hide, classes }: Props) => {
  const history = useHistory();

  function canGoBack() {
    // TODO: detect it in a proper way
    // If history length has only one that means that you don't navigate in the stack yet so you can't go back.
    // Only if the stack has minimum two elements that means that you can go back in the stack navigation.
    return history.length > 2;
  }

  const back = () => {
    if (hide) {
      hide();
    } else {
      if (canGoBack()) {
        history.goBack();
      } else {
        history.push('/');
      }
    }
  };

  return (
    <div className={classNames('Header Container', classes)}>
      <div
        className={classNames('Header__navigation', {
          'Header__navigation--hide': hide,
        })}
      >
        {!hideBackButton && !hide && <span className="Header__navigationBack" onClick={back} />}
        {hide && <span className="Header__navigationHide" onClick={back} />}
        <div className="Header__navigationTitle">{title}</div>
      </div>
    </div>
  );
};

export default Header;
