import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Header from '../Header/Header';
import './Article.css';
import Content from '../Content/Content';

type Props = {
  children: ReactNode;
  header?: ReactNode;
  wrapperClass?: string;
  headerTitle: string;
  headerHide?: () => void;
};

const Article = ({ children, header, wrapperClass, headerTitle, headerHide }: Props) => {
  return (
    <>
      <Header title={headerTitle} hide={headerHide} />
      <Content navigationBackground={true}>
        <div className={classNames('Article Page FadeIn', wrapperClass)}>
          <div className="Container Container--vertical">
            <div className="Article__header">{header}</div>
          </div>
          <div className="Page__grow Article__content Container Container--background">{children}</div>
        </div>
      </Content>
    </>
  );
};

export default Article;
