import React, { ChangeEvent, useEffect, useState } from 'react';
import Header from '../components/Header/Header';
import Loader from '../components/Loader/Loader';
import { Helmet } from 'react-helmet-async';
import './ReportsPage.css';
import { EmployeeReportDto, EmployeeReportEntry, LastReservationsReportDto, ReportsClient, ReservationEntry } from '../api/BookMeADeskApi';
import ApiConfig from '../config/ApiConfig';
import Api from '../services/Api';
import classNames from 'classnames';
import moment, { Moment } from 'moment';
import { chain } from 'lodash';
import Content from '../components/Content/Content';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const FileDownload = require('js-file-download');

type ReportsByDay = {
  date: string;
  reports: ReservationEntry[];
};

type ReportsByLetters = {
  letter: string;
  users: ReportsUser[];
};

type ReportsUser = {
  userName: string;
  reports: EmployeeReportEntry[];
};

const ReportsPage = () => {
  const reportsClient = new ReportsClient(ApiConfig.baseURL, Api);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [showLatest, setShowLatest] = useState<boolean>(true);
  const [employeeReport, setEmployeeReport] = useState<EmployeeReportDto>();
  const [latestReport, setLatestReport] = useState<LastReservationsReportDto>();
  const [date, setDate] = useState<Moment>(moment());
  const [userName, setUsername] = useState<string>('');

  const loadEmployeeReport = async (date: Moment) => {
    const report = await reportsClient.getEmployeeReport(date.month() + 1, date.year());
    setEmployeeReport(report);
  };

  const loadLatestReport = async () => {
    const report = await reportsClient.getLastReservationsReport();
    setLatestReport(report);
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await loadLatestReport();
      setIsLoading(false);
    };
    fetchData().catch((e) => {
      console.log(e);
      setIsLoading(false);
    });
  }, []);

  const switchTab = (newShowLatest: boolean) => async () => {
    if (!newShowLatest && !employeeReport) {
      setIsLoading(true);
      await loadEmployeeReport(date);
      setIsLoading(false);
    }

    setShowLatest(newShowLatest);
  };

  const changeMonth = async (amountToChange: number) => {
    if (!isLoading) {
      const newDate = moment(date).add(amountToChange, 'M');
      if (newDate.year() >= 2020 && newDate.year() <= 2099) {
        setDate(newDate);
        setIsLoading(true);
        await loadEmployeeReport(newDate);
        setIsLoading(false);
      }
    }
  };

  const reportsGroupedByDays = (): ReportsByDay[] => {
    return chain(latestReport?.entries)
      .groupBy((value) => moment(value.date).format())
      .map((value, key) => ({ date: key, reports: value }))
      .value()
      .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
  };

  const reportsGroupedByLetters = (): ReportsByLetters[] => {
    const groupedByUsers = chain(employeeReport?.entries)
      .groupBy((value) => value.userId ?? value.userName)
      .map((value, key) => {
        return { userId: key, userName: value[0].userName ?? '', reports: value }; // pass TS2532
      })
      .value()
      .sort((a, b) => {
        return a.userName > b.userName ? 1 : b.userName > a.userName ? -1 : 0;
      });

    return chain(groupedByUsers)
      .groupBy((value) => {
        return value.reports[0].userName!.charAt(0); // pass TS2532
      })
      .map((value, key) => {
        return {
          letter: key,
          users: value.map((reportGroup) => ({ userName: reportGroup.userName, reports: reportGroup.reports })),
        };
      })
      .value();
  };

  const handleChangeUsername = (e: ChangeEvent<HTMLInputElement>) => {
    setUsername(e.target.value);
  };

  const downloadReport = () => {
    reportsClient.downloadLastReservationsReport().then((response) => {
      FileDownload(response.data, 'report.txt');
    });
  };

  return (
    <>
      <Helmet>
        <title>Reports - Book me a desk!</title>
        <meta name="theme-color" content="#fffefd" />
      </Helmet>
      <Header title="Reports" />
      <Content>
        <div
          className={classNames('ReportsPage Page FadeIn', {
            'ReportsPage--disabled': isLoading,
          })}
        >
          <div className="Container Container--vertical Page__grow">
            <h2 className="ReportsPage__title">See reports</h2>
            <div className="ReportsPage__location">
              <span className="ReportsPage__locationIcon" />
              {latestReport?.officeAddress}
            </div>
            <div className="ReportsPage__tabs">
              <div
                onClick={switchTab(false)}
                className={classNames('ReportsPage__tab', {
                  'ReportsPage__tab--active': !showLatest,
                })}
              >
                <span className="ReportsPage__tabIcon ReportsPage__tabIcon--people" />
                Employees
              </div>
              <div
                onClick={switchTab(true)}
                className={classNames('ReportsPage__tab', {
                  'ReportsPage__tab--active': showLatest,
                })}
              >
                <span className="ReportsPage__tabIcon ReportsPage__tabIcon--calendar" />
                Calendar
              </div>
            </div>
            {isLoading ? (
              <Loader full />
            ) : (
              <div className="FadeIn">
                {showLatest ? (
                  <>
                    {latestReport ? (
                      <div className="ReportsPage__perDay FadeIn">
                        <div className="ReportsPage__perDayDownload">
                          Last 10 working days
                          <div className="ReportsPage__perDayDownloadButton" onClick={downloadReport}>
                            <span className="ReportsPage__perDayDownloadIcon" />
                            Download
                          </div>
                        </div>
                        {reportsGroupedByDays().map((day, index) => (
                          <div key={index} className="ReportsPage__perDayRecord">
                            <div className="ReportsPage__perDayRecordHeader">
                              <span className="ReportsPage__tabIcon ReportsPage__tabIcon--calendar" />
                              {moment(day.date).format('ddd, D MMM')}
                              <span className="ReportsPage__perDayRecordCounter">{day.reports.length}</span>
                            </div>
                            {day.reports.map((report, index) => (
                              <div key={index} className="ReportsPage__perDayRecordEntry">
                                {report.userName} - {report.type}
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    ) : (
                      <div className="ReportsPage__notFoundInformation">Could not found any reservations.</div>
                    )}
                  </>
                ) : (
                  <>
                    {latestReport ? (
                      <>
                        <div className="ReportsPage__monthPicker FadeIn">
                          <span
                            onClick={() => changeMonth(-1)}
                            className="ReportsPage__monthPickerArrow ReportsPage__monthPickerArrow--left"
                          />
                          {date.format('MMMM yyyy')}
                          <span
                            onClick={() => changeMonth(1)}
                            className="ReportsPage__monthPickerArrow ReportsPage__monthPickerArrow--right"
                          />
                        </div>
                        <div className="ReportsPage__perLetter FadeIn">
                          <div className="ReportsPage__perLetterSearchbar">
                            <input
                              type="text"
                              placeholder="Search for employee"
                              className="FormControl "
                              disabled={isLoading}
                              value={userName}
                              onChange={handleChangeUsername}
                            />
                          </div>
                          <div className="ReportsPage__perLetterHeader">
                            All visits
                            <span className="ReportsPage__perLetterHeaderCounter">{employeeReport?.totalVisits}</span>
                          </div>
                          {reportsGroupedByLetters()
                            .map((entry) => {
                              const filteredUsers = entry.users.filter((user) =>
                                user.userName.toLowerCase().includes(userName.toLowerCase()),
                              );
                              return { ...entry, users: filteredUsers };
                            })
                            .filter((entry) => entry.users)
                            .map((entry, index) =>
                              entry.users.length ? (
                                <div key={index} className="ReportsPage__perLetterRecord FadeIn">
                                  <div className="ReportsPage__perLetterRecordHeader">{entry.letter}</div>
                                  {entry.users.map((user, index) => (
                                    <div key={index} className="ReportsPage__perLetterRecordEntry">
                                      <span className="ReportsPage__perLetterRecordEntryLetter">{user.userName.substr(0, 1)}</span>
                                      <span>{user.userName.substr(1, user.userName.length - 1)}</span>
                                      <span className="ReportsPage__perLetterRecordEntryCounter">{user.reports.flatMap(x=>x.totalVisits)}</span>
                                    </div>
                                  ))}
                                </div>
                              ) : (
                                '' // otherwise it returns 0
                              ),
                            )}
                        </div>
                      </>
                    ) : (
                      <div className="ReportsPage__notFoundInformation">
                        Could not found any reservations within that period. Make sure that you have chosen the right month.
                      </div>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </Content>
    </>
  );
};

export default ReportsPage;
