import React from 'react';
import './HelloMessage.css';

type Props = {
  text: string;
};

const HelloMessage = ({ text }: Props) => {
  return (
    <div className="HelloMessage">
      <h2 className="HelloMessage__text">{text}</h2>
      <hr className="HelloMessage__line" />
    </div>
  );
};

export default HelloMessage;
