import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import BookPresencePage from './screens/BookPresencePage';
import { anonymousPathPrefix } from './index';

const AnonymousApp = () => {
  return (
    <HelmetProvider>
      <Router>
        <div id="App" className="App">
          <Switch>
            <Route exact path={anonymousPathPrefix}>
              <BookPresencePage isAnonymous={true} />
            </Route>
          </Switch>
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default AnonymousApp;
