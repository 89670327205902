import React, { useContext, useState } from 'react';
import { Button, ConfigProvider, DatePicker } from 'antd';
import { v4 as guid } from 'uuid';
import {
  AddReservationsRequestEntry,
  CreateReservationsRequest,
  ICreateReservationsRequest,
  ReservationsClient,
} from '../../api/BookMeADeskApi';
import ApiConfig from '../../config/ApiConfig';
import Api from '../../services/Api';
import moment, { Moment } from 'moment';
import locale from 'antd/es/locale/en_GB';
import { useHistory } from 'react-router-dom';
import './BookDesk.css';
import classNames from 'classnames';
import { isMobile } from '../../App';
import { Field, Formik, FormikProps } from 'formik';
import * as Yup from 'yup';
import PrivacyPolicyPage from '../../screens/PrivacyPolicyPage';
import { ModalContext } from '../Modal/Model';

type FormValues = {
  acceptTerms: boolean;
};

const FormSchema = Yup.object().shape<FormValues>({
  acceptTerms: Yup.boolean().oneOf([true], 'Please accept all terms').required(),
});

type DateEntry = {
  id: string;
  date: Moment | null;
  isAvailable: true;
};

type Props = {
  reservationType: string;
};

const BookDesk = ({ reservationType }: Props) => {
  const [dateEntries, setDateEntries] = useState<DateEntry[]>([{ date: null, id: guid(), isAvailable: true } as DateEntry]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  const reservationsClient = new ReservationsClient(ApiConfig.baseURL, Api);
  const maxDates = 5;

  const { show, hide } = useContext(ModalContext);

  const canAddAnotherDay = () => dateEntries.length < maxDates;

  const onAddAnotherDay = () => {
    if (canAddAnotherDay()) {
      setDateEntries((oldDates) => [...oldDates, { date: null, id: guid(), isAvailable: true } as DateEntry]);
    }
  };

  const updateDates = (date: Moment | null, id: string, isAvailable: boolean) => {
    const index = dateEntries.findIndex((x) => x.id === id);

    const dateEntry = dateEntries[index];
    const newDateEntry = {
      ...dateEntry,
      date: date,
      isAvailable: isAvailable,
    } as DateEntry;

    const newDateEntries = [...dateEntries];
    newDateEntries[index] = newDateEntry;
    setDateEntries(newDateEntries);
  };

  const onChange = async (date: Moment | null, id: string) => {
    let isAvailable = true;
    if (date) {
      const availability = await reservationsClient.checkAvailability(date.startOf('day').toDate(), reservationType);
      isAvailable = availability.isAvailable ?? false;
    }
    updateDates(date, id, isAvailable);
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const request: ICreateReservationsRequest = {
        reservations: dateEntries
          .filter((x) => x.date && x.isAvailable)
          .map((x) => {
            return { date: x.date?.startOf('day').toDate() } as AddReservationsRequestEntry;
          }),
        type: reservationType,
      };

      await reservationsClient.create(request as CreateReservationsRequest);
      history.push('/');
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const getDisabledDate = (date: Moment) => {
    if (date < moment().startOf('day')) return true;
    if (date > moment().add(1, 'weeks').endOf('isoWeek')) return true;
    if (dateEntries.filter((x) => x.date?.isSame(date, 'day')).length > 0) return true;
    return false;
  };

  const removeDate = (dateId: string) => () => {
    if (dateEntries.length > 1) {
      setDateEntries((oldDates) => oldDates.filter((x) => x.id !== dateId));
    }
  };

  return (
    <div className="BookDesk Container Container--vertical Page__grow">
      <h3 className="BookDesk__title">Choose dates</h3>
      <Formik
        enableReinitialize={false}
        validationSchema={FormSchema}
        validateOnMount={false}
        isInitialValid={false}
        initialValues={{
          acceptTerms: false,
        }}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isValid }: FormikProps<FormValues>) => (
          <>
            <ConfigProvider locale={locale}>
              {dateEntries.map((x, index) => (
                <div
                  key={x.id}
                  className={classNames('BookDesk__datePickerWrapper FadeIn', {
                    'BookDesk__datePickerWrapper--error': !x.isAvailable,
                  })}
                  id={`BookDesk__datePickerWrapper--${x.id}`}
                >
                  <DatePicker
                    className="BookDesk__datePicker"
                    format="DD-MM-yyyy"
                    transitionName="fade"
                    inputReadOnly={isMobile()}
                    dropdownClassName="BookDesk__datePickerDropdown"
                    onChange={(date) => onChange(date, x.id)}
                    placeholder="Choose date"
                    disabledDate={getDisabledDate}
                    showToday={false}
                    allowClear={false}
                    getPopupContainer={() => document.getElementById(`BookDesk__datePickerWrapper--${x.id}`) ?? document.body}
                  />
                  {dateEntries.length > 1 && index !== 0 && (
                    <div className="BookDesk__datePickerRemove" onClick={removeDate(x.id)}>
                      <span />
                    </div>
                  )}
                  {!x.isAvailable && (
                    <div className="BookDesk__datePickerError FadeIn">
                      Either the limit for this day has been reached or you have booked it already.
                    </div>
                  )}
                </div>
              ))}
            </ConfigProvider>
            {canAddAnotherDay() && (
              <div className="BookDesk__addAnotherDay FadeIn" onClick={onAddAnotherDay}>
                <span className="BookDesk__addAnotherDayIcon" />
                Add another day
              </div>
            )}
            <div className="Page__bottom">
              <div
                className={classNames('BookDesk__acceptTerms', {
                  'FormGroup--invalid': touched.acceptTerms && errors.acceptTerms,
                })}
              >
                <div className="FormCustomControl FormCustomControl--check">
                  <Field
                    type="checkbox"
                    name="acceptTerms"
                    id="acceptTerms"
                    className={classNames('FormCustomControl__input', {
                      'FormControl--invalid': touched.acceptTerms && errors.acceptTerms,
                    })}
                  />
                  <label htmlFor="acceptTerms" className="FormCustomControl__label">
                    Accept{' '}
                    <b
                      onClick={(e) => {
                        e.preventDefault();
                        show(<PrivacyPolicyPage hide={hide} />);
                      }}
                    >
                      Privacy Policy
                    </b>{' '}
                    and Cookie Policy
                  </label>
                </div>
                <div className="FormInvalidFeedback">{errors.acceptTerms}</div>
              </div>
              <Button
                className="Button Button--primary"
                type="default"
                block={true}
                onClick={() => onSubmit()}
                loading={isLoading}
                disabled={dateEntries.filter((x) => !x.date || !x.isAvailable).length > 0 || !isValid || isLoading}
              >
                Confirm booking
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default BookDesk;
