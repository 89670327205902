import React from 'react';
import './Navigation.css';
import { NavLink } from 'react-router-dom';

const Navigation = () => {
  return (
    <nav className="Navigation">
      <ul>
        <li>
          <NavLink to="/" exact activeClassName="active">
            <span className="Navigation__icon Navigation__icon--booking" />
            Booking
          </NavLink>
        </li>
        <li>
          <NavLink to="/settings" activeClassName="active">
            <span className="Navigation__icon Navigation__icon--cog" />
            Settings
          </NavLink>
        </li>
        <li>
          <NavLink to="/reports" activeClassName="active">
            <span className="Navigation__icon Navigation__icon--report" />
            Reports
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
