import React, { useEffect, useState } from 'react';
import Header from '../components/Header/Header';
import { Button } from 'antd';
import { IUpdateOfficeRequest, OfficesClient, OfficeSettingsDto, UpdateOfficeRequest } from '../api/BookMeADeskApi';
import ApiConfig from '../config/ApiConfig';
import Api from '../services/Api';
import Loader from '../components/Loader/Loader';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';
import { Field, Form, FormikProps, Formik } from 'formik';
import classNames from 'classnames';
import { Validation } from '../components/Forms/Validation';
import './SettingsPage.css';
import Content from '../components/Content/Content';

type FormValues = {
  address: string;
  amountOfDesks: string;
  additionalRestrictions: string | undefined;
};

const FormSchema = Yup.object().shape<FormValues>({
  address: Yup.string()
    .required("Address of an office is required")
    .max(100, 'Text is too long'),
  amountOfDesks: Yup.string()
    .required('Amount of desks is required')
    .matches(Validation.onlyPositiveDigitsRegex, 'Only positive digits are allowed')
    .matches(Validation.biggerThanZeroRegex, 'Number has to be bigger than zero'),
  additionalRestrictions: Yup.string().max(500, 'Text is too long'),
});

const SettingsPage = () => {
  const officesClient = new OfficesClient(ApiConfig.baseURL, Api);

  const [settings, setSettings] = useState<OfficeSettingsDto>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
  const [updateSucceed, setUpdateSucceed] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      const settings = await officesClient.getSettings();
      setSettings(settings);
    };
    fetchData()
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const onSubmit = async (form: FormValues) => {
    try {
      setIsLoadingForm(true);
      setUpdateSucceed(false);

      const request: IUpdateOfficeRequest = {
        address: form.address.trim(),
        amountOfDesks: Number.parseInt(form.amountOfDesks),
        additionalRestrictions: form.additionalRestrictions?.trim(),
      };

      await officesClient.update(request as UpdateOfficeRequest);
      setUpdateSucceed(true);
      setIsLoadingForm(false);
    } catch (error) {
      console.log(error);
      setIsLoadingForm(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Settings - Book me a desk!</title>
        <meta name="theme-color" content="#fffefd" />
      </Helmet>
      <Header title="Settings" />
      <Content>
        <div className="SettingsPage Page FadeIn">
          <div className="Container Container--vertical Page__grow">
            {isLoading ? (
              <Loader full />
            ) : (
              <div className="FadeIn">
                <h2 className="SettingsPage__title">Set settings for:</h2>
                <div className="SettingsPage__location">
                  <span className="SettingsPage__locationIcon" />
                  {settings?.name}
                </div>
                {settings && (
                  <Formik
                    enableReinitialize={false}
                    validationSchema={FormSchema}
                    validateOnMount={true}
                    initialValues={{
                      address: settings!.address!,
                      amountOfDesks: settings!.amountOfDesks!.toString(),
                      additionalRestrictions: settings!.additionalRestrictions,
                    }}
                    onSubmit={onSubmit}
                  >
                    {({ errors, touched, isValid, values }: FormikProps<FormValues>) => {
                      return (
                        <Form>
                          <div className="FormGroup">
                            <label htmlFor="address">Office address</label>
                            <Field
                              type="text"
                              component="textarea"
                              name="address"
                              id="address"
                              placeholder="Office address"
                              className={classNames('FormControl', {
                                'FormControl--invalid': touched.address && errors.address,
                              })}
                              disabled={isLoadingForm}
                            />
                            <div className="FormInvalidFeedback">{errors.address}</div>
                          </div>
                          <div className="FormGroup">
                            <label htmlFor="postalCode">Number of desks avaliable</label>
                            <Field
                              type="text"
                              name="amountOfDesks"
                              id="amountOfDesks"
                              placeholder="Write number here"
                              value={values.amountOfDesks}
                              className={classNames('FormControl', {
                                'FormControl--invalid': touched.amountOfDesks && errors.amountOfDesks,
                              })}
                              disabled={isLoadingForm}
                            />
                            <div className="FormInvalidFeedback">{errors.amountOfDesks}</div>
                          </div>
                          <div className="FormGroup">
                            <label htmlFor="additionalRestrictions">Additional restrictions (optional)</label>
                            <Field
                              type="text"
                              component="textarea"
                              name="additionalRestrictions"
                              id="additionalRestrictions"
                              placeholder="Describe restrictions"
                              className={classNames('FormControl', {
                                'FormControl--invalid': touched.additionalRestrictions && errors.additionalRestrictions,
                              })}
                              disabled={isLoadingForm}
                            />
                            <div className="FormInvalidFeedback">{errors.additionalRestrictions}</div>
                          </div>
                          <Button
                            className="Button Button--primary"
                            type="default"
                            htmlType="submit"
                            block={true}
                            loading={isLoadingForm}
                            disabled={isLoadingForm || !isValid}
                          >
                            Confirm settings
                          </Button>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
                {updateSucceed ? (
                  <div className="SettingsPage__updated FadeIn">
                    <span className="SettingsPage__updatedIcon" /> New settings confirmed
                  </div>
                ) : (
                  !isLoadingForm && (
                    <div className="SettingsPage__updateInformation">
                      Restriction changes will not affect the current period. The modifications will take effect from the week after next
                      week.
                    </div>
                  )
                )}
              </div>
            )}
          </div>
        </div>
      </Content>
    </>
  );
};

export default SettingsPage;
