import React from 'react';
import './PageHeader.css';
import classNames from 'classnames';

type Props = {
  icon?: string;
  messageTitle?: string;
  message?: string;
  classes?: string;
};

const PageHeader = ({ icon, message, messageTitle, classes }: Props) => {
  return (
    <div className={classNames('PageHeader Container', classes)}>
      {icon && <span className={`PageHeader__icon PageHeader__icon--${icon}`} />}
      {messageTitle && <div className="PageHeader__messageTitle">{messageTitle}</div>}
      {message && <div className="PageHeader__message">{message}</div>}
    </div>
  );
};

export default PageHeader;
