import React from 'react';
import './Logo.css';

const Logo = () => {
  return (
    <div className="Logo">
      <span className="Logo__icon" />
      <a href="mailto:endre.dingsor@fortedigital.no?subject=Book me a desk - feedback" className="Logo__feedback">
        <span className="Logo__feedbackIcon" />
        <span className="Logo__feedbackText">Send feedback</span>
      </a>
    </div>
  );
};

export default Logo;
