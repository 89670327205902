import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import Header from '../components/Header/Header';
import { Field, Form, Formik, FormikProps } from 'formik';
import classNames from 'classnames';
import { Button } from 'antd';
import * as Yup from 'yup';
import { Validation } from '../components/Forms/Validation';
import {
  AddReservationsRequestEntry,
  CreateAnonymousReservationsRequest,
  CreateReservationsRequest,
  ICreateAnonymousReservationsRequest,
  ICreateReservationsRequest,
  ReservationsClient,
} from '../api/BookMeADeskApi';
import './BookPresencePage.css';
import { useHistory } from 'react-router-dom';
import ApiConfig from '../config/ApiConfig';
import Api from '../services/Api';
import moment from 'moment';
import { AuthContext } from '../services/Auth';
import PrivacyPolicyPage from './PrivacyPolicyPage';
import { ModalContext } from '../components/Modal/Model';
import Content from '../components/Content/Content';
import PageHeader from '../components/PageHeader/PageHeader';

type Props = {
  isAnonymous: boolean;
};

type FormValues = {
  firstName: string;
  lastName: string;
  phone: string;
  acceptTerms: boolean;
};

const FormSchema = Yup.object().shape<FormValues>({
  firstName: Yup.string().required('First name  is required'),
  lastName: Yup.string().required('Last name is required'),
  phone: Yup.string()
    .required('Phone number is required')
    .min(5, 'Phone number is too short')
    .max(16, 'Phone number is too long')
    .matches(Validation.anyPhoneNumber, 'Phone number has to be in a valid format'),
  acceptTerms: Yup.boolean().oneOf([true], 'Please accept all terms').required(),
});

const BookPresencePage = ({ isAnonymous }: Props) => {
  const reservationsClient = isAnonymous ? new ReservationsClient(ApiConfig.baseURL) : new ReservationsClient(ApiConfig.baseURL, Api);
  const history = useHistory();

  const { show, hide } = useContext(ModalContext);

  const [isLoadingForm, setIsLoadingForm] = useState<boolean>(false);
  const [addSucceed, setAddSucceed] = useState<boolean>(false);
  const [addFailed, setAddFailed] = useState<boolean>(false);

  const createReservation = async () => {
    try {
      setIsLoadingForm(true);

      const request: ICreateReservationsRequest = {
        reservations: [{ date: moment().startOf('day').toDate() }] as AddReservationsRequestEntry[],
        type: 'Presence',
      };

      await reservationsClient.create(request as CreateReservationsRequest);
      history.push('/');
    } catch (error) {
      console.log(error);
      setAddFailed(true);
      setIsLoadingForm(false);
    }
  };

  const createAnonymousReservation = async (firstName: string, lastName: string, phone: string) => {
    try {
      setIsLoadingForm(true);
      setAddSucceed(false);
      setAddFailed(false);

      const request: ICreateAnonymousReservationsRequest = {
        firstName: firstName,
        lastName: lastName,
        phone: phone,
      };

      await reservationsClient.createAnonymous(request as CreateAnonymousReservationsRequest);
      setAddSucceed(true);
      setIsLoadingForm(false);
    } catch (error) {
      console.log(error);
      setAddFailed(true);
      setIsLoadingForm(false);
    }
  };

  const onSubmit = async (form: FormValues) => {
    if (isAnonymous) {
      await createAnonymousReservation(form.firstName, form.lastName, form.phone);
    } else {
      await createReservation();
    }
  };

  return (
    <>
      <Helmet>
        <title>Register my presence - Book me a desk!</title>
        <meta name="theme-color" content="#fffefd" />
      </Helmet>
      <Header hideBackButton={isAnonymous} title="Register my presence" />
      <Content>
        <div className="BookPresencePage Page FadeIn">
          <PageHeader
            icon="person"
            messageTitle="Welcome to Forte!"
            message="Hello, welcome in our office. Please register yor presence. Your data will be deleted in 10 days."
          />
          <div className="Container Container--vertical Page__grow">
            <Formik
              enableReinitialize={false}
              validationSchema={FormSchema}
              validateOnMount={false}
              isInitialValid={false}
              initialValues={
                isAnonymous
                  ? {
                      firstName: '',
                      lastName: '',
                      phone: '',
                      acceptTerms: false,
                    }
                  : {
                      firstName: AuthContext.getCachedUser()?.profile?.given_name,
                      lastName: AuthContext.getCachedUser()?.profile?.family_name,
                      phone: '000000000', // ignore phone validation
                      acceptTerms: false,
                    }
              }
              onSubmit={onSubmit}
            >
              {({ errors, touched, isValid, values }: FormikProps<FormValues>) => {
                return (
                  <Form className="BookPresencePage__form">
                    <div className="FormGroup">
                      <Field
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="First name"
                        value={values.firstName}
                        className={classNames('FormControl', {
                          'FormControl--invalid': touched.firstName && errors.firstName,
                        })}
                        disabled={isLoadingForm || !isAnonymous}
                      />
                      <div className="FormInvalidFeedback">{errors.firstName}</div>
                    </div>
                    <div className="FormGroup">
                      <Field
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Last name"
                        value={values.lastName}
                        className={classNames('FormControl', {
                          'FormControl--invalid': touched.lastName && errors.lastName,
                        })}
                        disabled={isLoadingForm || !isAnonymous}
                      />
                      <div className="FormInvalidFeedback">{errors.lastName}</div>
                    </div>
                    {isAnonymous && (
                      <div className="FormGroup">
                        <Field
                          type="text"
                          name="phone"
                          id="phone"
                          placeholder="Phone number"
                          value={values.phone}
                          className={classNames('FormControl', {
                            'FormControl--invalid': touched.phone && errors.phone,
                          })}
                          disabled={isLoadingForm}
                        />
                        <div className="FormInvalidFeedback">{errors.phone}</div>
                      </div>
                    )}
                    <div
                      className={classNames('FormGroup', {
                        'FormGroup--invalid': touched.acceptTerms && errors.acceptTerms,
                      })}
                    >
                      <div className="FormCustomControl FormCustomControl--check">
                        <Field
                          type="checkbox"
                          name="acceptTerms"
                          id="acceptTerms"
                          className={classNames('FormCustomControl__input', {
                            'FormControl--invalid': touched.acceptTerms && errors.acceptTerms,
                          })}
                        />
                        <label htmlFor="acceptTerms" className="FormCustomControl__label">
                          Accept{' '}
                          <b
                            onClick={(e) => {
                              e.preventDefault();
                              show(<PrivacyPolicyPage hide={hide} />);
                            }}
                          >
                            Privacy Policy
                          </b>{' '}
                          and Cookie Policy
                        </label>
                      </div>
                      <div className="FormInvalidFeedback">{errors.acceptTerms}</div>
                    </div>
                    <div className="Page__bottom">
                      {addSucceed || addFailed ? (
                        addSucceed ? (
                          <div className="BookPresencePage__message FadeIn">
                            <span className="BookPresencePage__messageSuccessIcon" /> Presence confirmed
                          </div>
                        ) : (
                          <div className="BookPresencePage__message FadeIn">
                            <span className="BookPresencePage__messageErrorIcon" /> Failed! Check your reservations.
                          </div>
                        )
                      ) : (
                        <>
                          <Button
                            className="Button Button--color"
                            type="default"
                            htmlType="submit"
                            block={true}
                            loading={isLoadingForm}
                            disabled={isLoadingForm || !isValid}
                          >
                            Register my presence
                          </Button>
                        </>
                      )}
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Content>
    </>
  );
};

export default BookPresencePage;
