import React, { useEffect, useState } from 'react';
import { OfficesClient, OfficeWithCurrentPeriodDto } from '../api/BookMeADeskApi';
import ApiConfig from '../config/ApiConfig';
import Api from '../services/Api';
import { Helmet } from 'react-helmet-async';
import Loader from '../components/Loader/Loader';
import CTA from '../components/CTA/CTA';
import './RestrictionsPage.css';
import Article from '../components/Article/Article';

const RestrictionsPage = () => {
  const officesClient = new OfficesClient(ApiConfig.baseURL, Api);
  const [office, setOffice] = useState<OfficeWithCurrentPeriodDto>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const text =
    'There should be fresh, clean air in all rooms. For jobs and work tasks at medium or high risk of exposure, our company recommends an increased ventilation rate through natural aeration or artificial ventilation, preferably without re-circulation of the air. In case of air recirculation, filters should be cleaned regularly.';

  useEffect(() => {
    const fetchData = async () => {
      const office = await officesClient.get();
      setOffice(office);
    };
    fetchData()
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>Current restrictions - Book me a desk!</title>
        <meta name="theme-color" content="#f8f4f1" />
      </Helmet>
      <Article
        wrapperClass="RestrictionsPage"
        headerTitle="Restrictions"
        header={
          <>
            <h2 className="Article__title">Current restrictions for:</h2>
            <div className="RestrictionsPage__location">
              <span className="RestrictionsPage__locationIcon" />
              {office?.address}
            </div>
          </>
        }
      >
        {isLoading ? (
          <Loader full />
        ) : (
          <>
            <div className="RestrictionsPage__people FadeIn">
              <span className="RestrictionsPage__peopleIcon" />
              <div className="RestrictionsPage__peopleText">{`Max ${office?.currentPeriod?.amountOfDesks} people in the office`}</div>
            </div>
            <div className="RestrictionsPage__additional">{office?.currentPeriod?.additionalRestrictions}</div>
            <hr className="Article__line" />
            <div className="Article__text">
              <p>{text}</p>
            </div>
            <div className="Page__bottom">
              <CTA />
            </div>
          </>
        )}
      </Article>
    </>
  );
};

export default RestrictionsPage;
