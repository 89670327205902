import React, { createContext, ReactNode, useContext, useState } from 'react';
import ReactDOM from 'react-dom';
import './Modal.css';
import classNames from 'classnames';

export type ModalContextProps = {
  visible: boolean;
  show: (node: ReactNode) => void;
  hide: () => void;
  content: ReactNode;
};

const useModal = () => {
  const [visible, setVisible] = useState(false);
  const [content, setModalContent] = useState<ReactNode>();

  const show = (content: ReactNode) => {
    setVisible(true);
    if (content) {
      setModalContent(content);
    }
  };

  const hide = () => {
    setVisible(false);
  };

  return { visible, show, hide, content };
};

export const ModalContext = createContext<ModalContextProps>({
  visible: false,
  show: () => null,
  hide: () => null,
  content: <></>,
});

const Modal = () => {
  const { visible, content } = useContext(ModalContext);
  if (visible) {
    return ReactDOM.createPortal(
      <div id="Modal" className={classNames('Modal')}>
        {content}
      </div>,
      document.getElementById('App') ?? document.body,
    );
  } else return null;
};

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const { visible, show, hide, content } = useModal();
  return (
    <ModalContext.Provider value={{ visible, show, hide, content }}>
      {children}
      <Modal />
    </ModalContext.Provider>
  );
};
