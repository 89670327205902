import React, { FC } from 'react';
import './Loader.css';
import classNames from 'classnames';

type Props = {
  full?: boolean;
  additionalClasses?: string;
};

const Loader: FC<Props> = ({ full, additionalClasses }: Props) => {
  return (
    <div
      className={classNames('Loader', additionalClasses, {
        'Loader--full': full,
      })}
    >
      <div className="Spinner" />
    </div>
  );
};

Loader.defaultProps = {
  full: false,
};

export default Loader;
