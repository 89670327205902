import React from 'react';
import './PrivacyPolicyPage.css';
import Article from '../components/Article/Article';

const PrivacyPolicyPage = ({ hide }: { hide?: () => void }) => {
  return (
    <Article
      wrapperClass="PrivacyPolicyPage"
      headerTitle="Back to registration"
      headerHide={hide}
      header={
        <>
          <h2 className="Article__title">Privacy Policy</h2>
        </>
      }
    >
      <h3 className="Article__ingress">GDPR details</h3>
      <hr className="Article__line" />
      <div className="Article__text">
        <p>
          There should be fresh, clean air in all rooms. For jobs and work tasks at medium or high risk of exposure, our company recommends
          an increased ventilation rate through natural aeration or artificial ventilation, preferably without re-circulation of the air. In
          case of air recirculation, filters should be cleaned regularly.
        </p>
        <p>
          There should be fresh, clean air in all rooms. For jobs and work tasks at medium or high risk of exposure, our company recommends
          an increased ventilation rate through natural aeration or artificial ventilation, preferably without re-circulation of the air. In
          case of air recirculation, filters should be cleaned regularly.
        </p>
        <p>
          There should be fresh, clean air in all rooms. For jobs and work tasks at medium or high risk of exposure, our company recommends
          an increased ventilation rate through natural aeration or artificial ventilation, preferably without re-circulation of the air. In
          case of air recirculation, filters should be cleaned regularly.
        </p>
        <p>
          There should be fresh, clean air in all rooms. For jobs and work tasks at medium or high risk of exposure, our company recommends
          an increased ventilation rate through natural aeration or artificial ventilation, preferably without re-circulation of the air. In
          case of air recirculation, filters should be cleaned regularly.
        </p>
      </div>
    </Article>
  );
};

export default PrivacyPolicyPage;
